import React from 'react';
import PropTypes from 'prop-types';
import Authenticate from '../../../../components/Identity/Authenticate';
import Layout from '../../../../components/Admin/Layout';
import ContentTabView from
  '../../../../components/Admin/Performance/ContentTabView';
import * as styles from '../../../../styles/admin/Account.module.scss';
import performanceTabs from '../../../../performanceTabs';
import { AdminProvider } from '../../../../context/Admin/AdminContext';

function ContentView(props) {
  const { identityProviderClient } = props;

  return (
    <AdminProvider>
      <div className={styles.accountPageWrapper}>
        <Layout
          title="Performance & Insights"
          Component={ContentTabView}
          tabs={performanceTabs}
          activeTab="Content"
          identityProviderClient={identityProviderClient}
          moreInfo="<p className='text'>In this section, Admins and Managers can see user performance, skills analysis and overall course performance</p>"
        />
      </div>
    </AdminProvider>
  );
}
ContentView.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Authenticate(ContentView);
