/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';
import TableComponent from '../../Shared/Table/TableComponent';
import * as styles from '../../../styles/admin/Content.module.scss';
import AdminContext from '../../../context/Admin/AdminContext';
import { fetchMetrics } from '../Services/MetricsApiService';
import CourseEnrollmentStatsView from './CourseEnrollmentStatsView';
import { fetchCourseById } from './PerformanceService';
import { CourseAccessType } from '../../../API';
import { truncateText } from '../../../services/utils';

function ContentTabView() {
  const { companyInfo } = useContext(AdminContext);
  const [skills, setSkills] = useState([]);
  const [coursePerformance, setCoursePerformance] = useState([]);

  const [tableLoader, setTableLoader] = useState(true);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [coursesInSelectedDateRange, setCoursesInSelectedDateRange] = useState([]);
  const [activeTab, setActiveTab] = useState('upskillist');

  const navigateToCourseReport = async (record) => {
    if (activeTab === 'upskillist') {
      navigate(`course-report/${record.key}/`);
    } else {
      navigate(`course-report/${record.key}/`, {
        state: {
          courseAccessType: 'PRIVATE',
        },
      });
    }
  };

  const getCourseDetails = async (response) => {
    const courseIds = response?.data.map((data) => (
      data?.relationships?.course?.data?.id
    ));
    const promises = courseIds.map((id) => (
      fetchCourseById(id)
    ));
    const courseDetails = await Promise.all(promises);
    return courseDetails;
  };

  const mergeCoursePerformanceResponse = async (response) => {
    const dataMap = new Map();
    const skillsSet = new Set();
    const tempCoursePerformance = [];
    response?.data.forEach((data) => {
      const courseId = data?.relationships?.course?.data?.id;
      dataMap.set(courseId, data);
    });

    const courseDetails = await getCourseDetails(response);

    courseDetails.forEach((course) => {
      const courseId = course?.id;
      const metricData = dataMap.get(courseId);
      course?.categories.forEach((category) => {
        if (category) {
          skillsSet.add(category);
        }
      });
      const usersEnrolled = metricData?.attributes?.usersEnrolled;
      if (metricData && usersEnrolled) {
        const attributes = metricData?.attributes;
        const usersCompleted = metricData?.attributes?.usersCompleted;
        const completion = usersEnrolled
          ? Math.round((usersCompleted / usersEnrolled) * 100, 10) : 0;
        tempCoursePerformance.push({
          key: course?.id,
          name: course?.courseName,
          createdAt: course?.createdAt,
          totalEnrollments: attributes?.usersEnrolled,
          completedEnrollments: attributes?.usersCompleted,
          consumption: attributes?.contentConsumptionHours,
          completion,
          category: course?.categories[0],
        });
      }
    });
    return { coursePerformanceData: tempCoursePerformance, skillsSet };
  };

  const getCoursePerformance = async (from = '', to = '', courseAccessType = CourseAccessType.PUBLIC) => {
    try {
      const enterpriseId = companyInfo?.id;
      const path = '/contentperformancestats';
      const query = {
        courseAccessType,
        enterpriseId,
        ...(from && { from: dayjs(from).toISOString() }),
        ...(to && { to: dayjs(to).toISOString() }),
      };
      const response = await fetchMetrics(path, query);
      const mergedResponse = await mergeCoursePerformanceResponse(response);
      const skillsList = Array.from(mergedResponse?.skillsSet);
      return {
        skills: skillsList,
        courseInfo: mergedResponse?.coursePerformanceData,
      };
    } catch (error) {
      setTableLoader(false);
      return [];
    }
  };

  const filterBySkills = (skillsList) => {
    setSelectedSkills(skillsList);
    if (coursesInSelectedDateRange?.length > 0) {
      const filteredData = coursesInSelectedDateRange.filter((
        course,
      ) => skillsList.includes(course?.category));
      return filteredData;
    }
    return [];
  };

  const filterByDateHandler = async (selectedDate) => {
    setTableLoader(true);
    try {
      let courses;
      if (selectedDate?.startDate.isSame(selectedDate?.endDate)) {
        courses = coursePerformance;
      } else {
        const from = selectedDate?.endDate;
        const to = selectedDate?.startDate;
        const courseAccessType = activeTab === 'upskillist' ? CourseAccessType.PUBLIC : CourseAccessType.PRIVATE;
        const response = await getCoursePerformance(from, to, courseAccessType);
        courses = response?.courseInfo;
      }
      setCoursesInSelectedDateRange(courses);
      if (activeTab === 'upskillist') {
        if (selectedSkills?.length === skills?.length) {
          return courses;
        }
        const filteredCourses = courses.filter(
          (course) => selectedSkills.includes(course?.category),
        );
        return filteredCourses;
      }
      return courses;
    } catch (error) {
      return [];
    } finally {
      setTableLoader(false);
    }
  };

  const loadDependencies = async (courseAccessType = CourseAccessType.PUBLIC) => {
    const response = await getCoursePerformance('', '', courseAccessType);
    setSkills(response?.skills);
    setSelectedSkills(response?.skills);
    setCoursePerformance(response?.courseInfo);
    setCoursesInSelectedDateRange(response?.courseInfo);
    setTableLoader(false);
  };

  const toggleActiveTab = (tab) => {
    const courseAccessType = tab === 'upskillist' ? CourseAccessType.PUBLIC : CourseAccessType.PRIVATE;
    setActiveTab(tab);
    setTableLoader(true);
    loadDependencies(courseAccessType);
  };

  useEffect(() => {
    if (companyInfo?.id) {
      loadDependencies();
    }
  }, [companyInfo]);

  const columns = [
    {
      title: 'Course Name',
      dataIndex: 'name',
      render: (text, record) => (
        <button
          type="button"
          onClick={() => { navigateToCourseReport(record); }}
          className={styles.nameButton}
        >
          {text}
        </button>
      ),
      width: 150,
    },
    {
      title: 'Total Enrollments',
      dataIndex: 'totalEnrollments',
      sorter: (a, b) => a.totalEnrollments - b.totalEnrollments,
      width: 130,
    },
    {
      title: 'Completed Enrollments',
      dataIndex: 'completedEnrollments',
      sorter: (a, b) => a.completedEnrollments - b.completedEnrollments,
      width: 150,
    },
  ];
  if (activeTab === 'upskillist') {
    columns.push({
      title: 'Consumption (in Hrs)',
      dataIndex: 'consumption',
      sorter: (a, b) => a.consumption - b.consumption,
      width: 130,
    });
  }
  columns.push({
    title: 'Completion %',
    dataIndex: 'completion',
    sorter: (a, b) => a.completion - b.completion,
    width: 130,
  });

  const headers = columns.map((column) => ({ label: column.title, key: column.dataIndex }));

  const filters = {
    datetime: {
      name: 'Date',
      type: 'date',
      filterHandler: filterByDateHandler,
    },
  };

  if (activeTab === 'upskillist') {
    filters.skills = {
      name: 'Skills',
      type: 'checkbox',
      filterHandler: filterBySkills,
      options: skills,
    };
  }

  const truncatedText = truncateText(companyInfo?.name);

  return (
    <>
      <div className={styles.ContentContainer}>
        <div className={styles.topcontainer}>
          <div className={styles.SkillPopularity}>
            <CourseEnrollmentStatsView />
          </div>
        </div>
        <p className={styles.courseConatinertitle}> Course Performance</p>
        {companyInfo?.scormCloudAppID && (
        <div className={styles.tabs}>
          <button
            type="button"
            id="admin-performance-content-course-performance-switch-tab-public-courses"
            className={activeTab === 'upskillist' ? styles.active : ''}
            onClick={() => toggleActiveTab('upskillist')}
          >
            Upskillist Courses
          </button>
          <button
            type="button"
            id="admin-performance-content-course-performance-switch-tab-private-courses"
            className={activeTab === 'private' ? styles.active : ''}
            onClick={() => toggleActiveTab('private')}
          >
            {`${truncatedText}'s Courses`}
          </button>
        </div>
        )}
        <TableComponent
          globalSearchPlaceholder="Search by course name"
          displayColumns={columns}
          allData={coursePerformance}
          tableLoader={tableLoader}
          headers={headers}
          fileName="Course Performance Report.csv"
          filters={filters}
          performanceTabOptions={{
            resetData: activeTab,
          }}
          domIDs={{
            Download: 'admin-performance-content-download',
          }}
        />
      </div>
    </>
  );
}

export default ContentTabView;
