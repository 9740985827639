// extracted by mini-css-extract-plugin
export var ContentContainer = "Content-module--ContentContainer--3DHd3";
export var tabs = "Content-module--tabs--Vyike";
export var active = "Content-module--active--a_sqJ";
export var topcontainer = "Content-module--topcontainer--3Wvdw";
export var SkillPopularity = "Content-module--SkillPopularity--3Wsd_";
export var CourseContainer = "Content-module--CourseContainer--1huJC";
export var CourseNumber = "Content-module--CourseNumber--3khOL";
export var CoursePara = "Content-module--CoursePara--3ieJk";
export var progesscontainerLeft = "Content-module--progesscontainerLeft--aQ-VT";
export var progesscontainerRight = "Content-module--progesscontainerRight--1p9dS";
export var tableTitle = "Content-module--tableTitle--3AVxa";
export var searchAndFilterContainer = "Content-module--searchAndFilterContainer--Fcays";
export var searchInputContainer = "Content-module--searchInputContainer--1tL4R";
export var searchIconContainer = "Content-module--searchIconContainer--1N1sd";
export var searchInput = "Content-module--searchInput--r11VG";
export var dropdownMenu = "Content-module--dropdownMenu--BXiub";
export var durationFilter = "Content-module--durationFilter--1bHz6";
export var durationFilterExport = "Content-module--durationFilterExport--UixWQ";
export var calendarImagePlacehoder = "Content-module--calendarImagePlacehoder--2Ko8e";
export var durationFilterActive = "Content-module--durationFilterActive--2Cn5d";
export var filterText = "Content-module--filterText--34ZUC";
export var filterTextExport = "Content-module--filterTextExport--SFvEz";
export var eachMenuData = "Content-module--eachMenuData--GfSmp";
export var rangeContainer = "Content-module--rangeContainer--1EKMr";
export var CourseConatiner = "Content-module--CourseConatiner--3kbf5";
export var courseConatinertitle = "Content-module--courseConatinertitle--1JIez";
export var dropDownArrow = "Content-module--dropDownArrow--3EIy3";
export var nameButton = "Content-module--nameButton--CiNzh";
export var table = "Content-module--table--21a9p";