/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import { Progress, Spin } from 'antd';
import * as styles from '../../../styles/admin/CourseEnrollmentStatsView.module.scss';
import AdminContext from '../../../context/Admin/AdminContext';
import { fetchMetrics } from '../Services/MetricsApiService';
import { fetchCourseById } from './PerformanceService';
import { CourseAccessType } from '../../../API';
import { truncateText } from '../../../services/utils';
import { CourseEnrollmentsStatsResponse } from '../../../types/CourseEnrollmentsStats';
import { Course } from '../../../models';

interface CourseEnrollmentsStats {
  attributes: Attributes,
  course: Course
}

interface Attributes {
  totalEnrolledPercentage: number,
  totalEnrollments: number,
  usersEnrolled: number,
}

enum ActiveTab { UPSKILLIST_COURSE, PRIVATE_COURSE}

const CourseEnrollmentStatsView:React.FC = () => {
  const { companyInfo } = useContext(AdminContext);
  const [courseEnrollmentsStats, setCourseEnrollmentsStats] = useState<
  CourseEnrollmentsStats[]>([]);
  const [activeTab, setActiveTab] = useState(ActiveTab.UPSKILLIST_COURSE);
  const [loader, setLoader] = useState(true);

  const getEnrolledCourses = async (courseIds:string[]) => {
    const promises = courseIds.map((courseId) => (fetchCourseById(courseId)));
    const enrolledCourses = await Promise.all(promises);
    return enrolledCourses;
  };

  const mergeResponse = async (response:CourseEnrollmentsStatsResponse) => {
    const dataMap = new Map();
    const mergedData = [] as CourseEnrollmentsStats[];
    const courseIds = [] as Array<string>;
    let totalEnrollments = 0;
    response?.data.forEach((data) => {
      const courseId = data?.relationships?.course?.data?.id;
      const usersEnrolled = data?.attributes?.usersEnrolled;
      totalEnrollments += usersEnrolled;
      if (usersEnrolled > 0) {
        dataMap.set(courseId, data);
        courseIds.push(courseId);
      }
    });
    const courseDetails = await getEnrolledCourses(courseIds);
    courseDetails.forEach((course) => {
      const courseId = course?.id;
      const metricData = dataMap.get(courseId);
      const { usersEnrolled } = metricData?.attributes;
      if (usersEnrolled && metricData && courseId) {
        const totalEnrolledPercentage = totalEnrollments
          ? Math.ceil((usersEnrolled / totalEnrollments) * 100) : 0;
        mergedData.push({
          course,
          attributes: {
            ...metricData?.attributes,
            totalEnrollments,
            totalEnrolledPercentage,
          },
        });
      }
    });
    return mergedData;
  };

  const fetchCourseEnrollmentStats = async (courseAccessType = CourseAccessType.PUBLIC) => {
    try {
      const enterpriseId = companyInfo?.id;
      const path = '/contentenrollmentstats';
      const response = await fetchMetrics(path, {
        courseAccessType,
        enterpriseId,
      });
      const courseEnrollmentsData = await mergeResponse(response);
      setCourseEnrollmentsStats(courseEnrollmentsData);
    } catch (error) {
      setCourseEnrollmentsStats([]);
    } finally {
      setLoader(false);
    }
  };

  const toggleActiveTab = (tab:ActiveTab) => {
    setActiveTab(tab);
    setLoader(true);
    const courseAccessType = tab === ActiveTab.UPSKILLIST_COURSE
      ? CourseAccessType.PUBLIC : CourseAccessType.PRIVATE;
    fetchCourseEnrollmentStats(courseAccessType);
  };

  useEffect(() => {
    if (companyInfo?.id) {
      fetchCourseEnrollmentStats();
    }
  }, [companyInfo?.id]);

  const truncatedText = truncateText(companyInfo?.name);

  return (
    <>
      <h1 className={styles.title}> Skill Performance</h1>
      {companyInfo?.scormCloudAppID && (
      <div className={styles.tabs}>
        <button
          type="button"
          id="admin-performance-content-skill-performance-switch-tab-public-courses"
          className={activeTab === ActiveTab.UPSKILLIST_COURSE ? styles.active : ''}
          onClick={() => toggleActiveTab(ActiveTab.UPSKILLIST_COURSE)}
        >
          Upskillist Courses
        </button>
        <button
          type="button"
          id="admin-performance-content-skill-performance-switch-tab-private-courses"
          className={activeTab === ActiveTab.PRIVATE_COURSE ? styles.active : ''}
          onClick={() => toggleActiveTab(ActiveTab.PRIVATE_COURSE)}
        >
          {`${truncatedText}'s Courses`}
        </button>
      </div>
      )}
      <p className={styles.subtitle}>
        Total enrolled % of registered Learners
      </p>
      <div className={styles.courseEnrollmentStatsContainer}>
        {loader ? <Spin size="default" />
          : courseEnrollmentsStats.map((skill:any) => (
            <div className={styles.content} key={skill?.course?.id}>
              <div className={styles.contentProgress}>
                <p>{skill?.course?.courseName}</p>
                <p>
                  {`${skill?.attributes?.totalEnrolledPercentage}%`}
                </p>
              </div>
              <Progress
                percent={skill?.attributes?.totalEnrolledPercentage}
                strokeColor="rgba(156, 106, 249, 0.5)"
                trailColor="#f0f4f7"
                strokeWidth={8}
                showInfo={false}
                className={styles.progressBar}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default CourseEnrollmentStatsView;
